import { ReactElement, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Loader } from '@calm-web/design-system';

import SpaLayout from '@/components/layout/LayoutWrapper';
import AccessCodes from '@/components/pages/AccessCodes';
import Account, { CreateAccount } from '@/components/pages/Account';
import HealthSponsorshipDetail from '@/components/pages/Account/HealthSponsorshipDetail';
import ApiIntegration from '@/components/pages/Docs/ApiIntegration';
import SsoIntegration from '@/components/pages/Docs/SsoIntegration';
import GetSetup from '@/components/pages/GetSetup';
import Groups from '@/components/pages/Groups';
import Home from '@/components/pages/Home';
import Login from '@/components/pages/Login';
import Plan from '@/components/pages/Plan';
import Referral from '@/components/pages/Referral';
import Reporting from '@/components/pages/Reporting';
import AllFeedbackSubpage from '@/components/pages/Reporting/AllFeedbackSubpage';
import CHReportingPreview from '@/components/pages/Reporting/CHReportingPreview';
import Resources from '@/components/pages/Resources';
import Spa from '@/components/pages/Spa';
import SSOSetup from '@/components/pages/SSOSetup';
import UserFeedback from '@/components/pages/UserFeedback';
import Users from '@/components/pages/Users';
import AuthProvider from '@/components/providers/AuthProvider';
import BannerProvider from '@/components/providers/BannerProvider';
import PathwaysRoutes from '@/components/routes/PathwaysRoutes';
import ErrorBoundary from '@/components/ui/ErrorBoundary';
import FullPageWrapper from '@/components/ui/FullPageWrapper';
import Nav from '@/components/ui/Nav';
import SimpleNav from '@/components/ui/Nav/SimpleNav';
import PartnerSetup from '@/components/ui/PartnerSetup';
import { EnvConfig } from '@/env_config';
import { calmLogger } from '@/utils/calmLogger';

const navRoutesRequirePartner = [
	'/:partnerId/home',
	'/:partnerId/getsetup',
	'/:partnerId/account',
	'/:partnerId/users',
	'/:partnerId/access-codes',
	'/:partnerId/resources',
	'/:partnerId/plan',
	'/:partnerId/reporting',
	'/:partnerId/pathways',
	'/:partnerId/pathways/create',
	'/:partnerId/pathways/edit',
	'/:partnerId/reporting/feedback',
	'/:partnerId/referral',
	'/:partnerId/preview-reporting',
];

const navRoutesNoPartner = ['/create', '/groups'];

function RootContainer(): ReactElement {
	const shouldSetUpMocking = process.browser && EnvConfig.mswMockingEnabled;
	const [loading, setLoading] = useState(shouldSetUpMocking);

	useEffect(() => {
		const setUpMocking = async (): Promise<void> => {
			// As recommended by https://github.com/mswjs/msw/issues/1877
			if (typeof window !== 'undefined') {
				// Set up dev mocking
				const { worker } = await import('@/mocks/browser');
				await worker.start({ onUnhandledRequest: 'bypass' });
				worker.listHandlers();
				setLoading(false);
			}
		};
		if (shouldSetUpMocking) {
			setUpMocking().catch(err => {
				calmLogger.error('Error in RootContainer setUpMocking', {}, err);
			});
		}
	}, [shouldSetUpMocking]);

	if (loading) {
		return (
			<FullPageWrapper>
				<Loader color="gray1" />
			</FullPageWrapper>
		);
	}

	return (
		<Spa>
			<ErrorBoundary>
				<SpaLayout>
					<BannerProvider>
						<Switch>
							<Route path="/login" exact component={Login} />
							<Route path="/docs/api" exact component={ApiIntegration} />
							<Route path="/docs/sso" exact component={SsoIntegration} />
							<Route path="/feedback" exact component={UserFeedback} />
							<Route path={navRoutesNoPartner} exact>
								<AuthProvider>
									<SimpleNav>
										<Route path="/create" exact component={CreateAccount} />
										<Route path="/groups" exact component={Groups} />
									</SimpleNav>
								</AuthProvider>
							</Route>
							<Route path={navRoutesRequirePartner}>
								<AuthProvider>
									<PartnerSetup>
										<Nav>
											<Route path="/:partnerId/home" component={Home} />
											<Route path="/:partnerId/getsetup" component={GetSetup} />
											<Route path="/:partnerId/account" component={Account} exact />
											<Route path="/:partnerId/referral" component={Referral} />
											<Route path="/:partnerId/account/sso" component={SSOSetup} />
											<Route
												path="/:partnerId/account/health-sponsorships"
												exact
												component={HealthSponsorshipDetail}
											/>
											<Route
												path="/:partnerId/account/health-sponsorships/:sponsorshipId"
												component={HealthSponsorshipDetail}
											/>
											<Route path="/:partnerId/users" component={Users} />
											<Route path="/:partnerId/access-codes" component={AccessCodes} />
											<PathwaysRoutes />
											<Route path="/:partnerId/resources" component={Resources} />
											<Route path="/:partnerId/plan" component={Plan} />
											<Switch>
												<Route path="/:partnerId/reporting/feedback" component={AllFeedbackSubpage} />
												<Route path="/:partnerId/reporting" component={Reporting} />
											</Switch>
											<Route path="/:partnerId/preview-reporting" component={CHReportingPreview} />
										</Nav>
									</PartnerSetup>
								</AuthProvider>
							</Route>
							<Route exact path="/">
								<Redirect to="/groups" />
							</Route>
						</Switch>
					</BannerProvider>
				</SpaLayout>
			</ErrorBoundary>
		</Spa>
	);
}

export default RootContainer;
