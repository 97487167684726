import { ReactElement, useCallback, useMemo } from 'react';

import { Card, FormInput, RadioGroup, Text, TextArea, TextElements } from '@calm-web/design-system';
import { stringFromModelValue } from '@calm-web/use-form';

import CellTitle from '@/components/ui/CellTitle';
import DatePicker from '@/components/ui/DatePicker';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { usePermissions } from '@/hooks/auth';
import { EditHealthSponsorshipFormProps } from '@/hooks/forms/useHealthSponsorshipForm';
import { dateFromIso8601DateForDatePicker, iso8601Date } from '@/utils/helpers';

import { CellSubtitle } from '../styles';
import { DatePickerWrapper, RadioGroupWrapper } from './styles';

function BaseSponsorshipForm({
	formProps,
	onFocus,
	isDependentGroup,
}: {
	formProps: EditHealthSponsorshipFormProps;
	onFocus?: () => void;
	isDependentGroup?: boolean;
}): ReactElement {
	const [hasValidPermissions, actions] = usePermissions();
	const handleDateChange = useCallback(
		(name: 'starts_at' | 'ends_at'): ((d: Date | null) => void) =>
			(d: Date | null) => {
				if (!d) {
					return formProps.setProperty(name, '');
				}
				return formProps.setProperty(name, iso8601Date(d));
			},
		[formProps],
	);
	const partner = useDefinedPartner();
	const minDate = dateFromIso8601DateForDatePicker(partner.contract_starts_at);
	const maxDate = dateFromIso8601DateForDatePicker(partner.contract_expires_at);
	const startsAtFormProps = formProps.bindWithErrorProps('starts_at', 'date');
	const startsAt = useMemo(
		() => dateFromIso8601DateForDatePicker(stringFromModelValue(startsAtFormProps.value)),
		[startsAtFormProps.value],
	);
	const onChangeStartsAt = useMemo(() => handleDateChange('starts_at'), [handleDateChange]);
	const endsAtFormProps = formProps.bindWithErrorProps('ends_at', 'date');
	const endsAt = useMemo(
		() => dateFromIso8601DateForDatePicker(stringFromModelValue(endsAtFormProps.value)),
		[endsAtFormProps.value],
	);
	const onChangeEndsAt = useMemo(() => handleDateChange('ends_at'), [handleDateChange]);

	const autoExpireOptions = [
		{ label: 'No', value: 'null' },
		{ label: '30 day', value: 'P30D' },
		{ label: '60 day', value: 'P60D' },
		{ label: '6 month', value: 'P6M' },
		{ label: '1 year', value: 'P1Y' },
		...(process.env.CALM_ENV === 'dev' ? [{ label: '5 minutes', value: 'PT5M' }] : []),
		...(process.env.CALM_ENV === 'dev' ? [{ label: '5 seconds', value: 'PT5S' }] : []),
	];

	const groupNameInputProps = formProps.bindWithErrorProps('display_name', 'text');

	return (
		<Card onFocus={onFocus}>
			<CellTitle>Sponsor Group Customization</CellTitle>
			<CellSubtitle>
				Detail this segment of your members. All configurations need at least one sponsorship group.
			</CellSubtitle>
			<FormInput
				{...groupNameInputProps}
				label="Group Name"
				disabled={!hasValidPermissions('health_sponsorship_display_name', [actions.UPDATE])}
			/>
			<TextArea
				{...formProps.bindWithErrorProps('description', 'text')}
				label="Description"
				disabled={!hasValidPermissions('health_sponsorship_description', [actions.UPDATE])}
			/>
			{!isDependentGroup && (
				<>
					<DatePickerWrapper>
						<Text el={TextElements.Label} id="starts-at-label">
							Start Date
						</Text>
						<DatePicker
							startDate={startsAt}
							minDate={minDate}
							maxDate={maxDate}
							onChange={onChangeStartsAt}
							labelledBy="starts-at-label"
							placement="top"
							width="100%"
							disabled={!hasValidPermissions('health_sponsorship_starts_at', [actions.UPDATE])}
						/>
					</DatePickerWrapper>
					<DatePickerWrapper>
						<Text el={TextElements.Label} id="ends-at-label">
							End Date
						</Text>
						<DatePicker
							startDate={endsAt}
							minDate={minDate}
							maxDate={maxDate}
							onChange={onChangeEndsAt}
							labelledBy="ends-at-label"
							placement="top"
							width="100%"
							disabled={!hasValidPermissions('health_sponsorship_ends_at', [actions.UPDATE])}
						/>
					</DatePickerWrapper>
					<RadioGroup
						{...formProps.bindWithErrorProps('is_demo', 'radio')}
						label="Is Demo Sponsorship?"
						options={[
							{
								label: 'Yes',
								value: 'true',
							},
							{
								label: 'No',
								value: 'false',
							},
						]}
						asCards
						display="flex"
						disabled={!hasValidPermissions('health_sponsorship_is_demo', [actions.UPDATE])}
					/>
					<RadioGroupWrapper>
						<RadioGroup
							{...formProps.bindWithErrorProps('auto_expire_duration', 'radio')}
							label="Should sponsorship groups auto-expire?"
							options={autoExpireOptions}
							asCards
							display="flex"
							disabled={!hasValidPermissions('health_sponsorship_auto_expire_duration', [actions.UPDATE])}
							noMarginBottom
						/>
					</RadioGroupWrapper>
				</>
			)}
		</Card>
	);
}

export default BaseSponsorshipForm;
