import JSZip from 'jszip';
import { noop } from 'lodash';
import { DateTime } from 'luxon';
import querystring from 'query-string';
import { Key, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Modal, ProgressBar } from '@calm-web/design-system';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import ReportingContextProvider, { timeframeValues } from '@/components/providers/ReportingContextProvider';
import IncreaseCoverage from '@/components/ui/IncreaseCoverage';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useContentReport } from '@/hooks/api/reporting/useContentReport';
import { useMoodReport } from '@/hooks/api/reporting/useMoodReport';
import { useMostRecentEndDate } from '@/hooks/api/reporting/useMostRecentEndDate';
import { usePortraits } from '@/hooks/api/reporting/usePortraits';
import { useSessionsReport } from '@/hooks/api/reporting/useSessionsReport';
import { useSignupsAndEngagement } from '@/hooks/api/reporting/useSignupsAndEngagement';
import { useTopContent } from '@/hooks/api/reporting/useTopContent';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { useFeedbackHighlighted } from '@/hooks/api/useFeedback';
import { PartnerMilestoneType, useRecordMilestoneOnLoad } from '@/hooks/api/useMilestones';
import { setBannerMessage } from '@/store/actions';
import { Partner } from '@/types/store/reducers';
import { isReportingPrivacyError, isReportNotFoundError } from '@/utils/apiRequest/errors';
import { dateFromIso8601DateForDatePicker } from '@/utils/helpers';
import {
	checkIsShowingSegmented,
	paramsToSegments,
	SegmentSelectState,
	segmentsToParams,
} from '@/utils/segments';
import { isSelfServePlan } from '@/utils/SkuUtils';
import InfoOutline from 'icons/info-outline.svg';

import Content from '../Content';
import HighlightedFeedbackBox from '../HighlightedFeedbackBox';
import HourlyUsage from '../HourlyUsage';
import MoodCheckin from '../MoodCheckin';
import Portraits from '../Portraits';
import ReportingButtonContainer from '../ReportingButtonContainer';
import SegmentsSection from '../SegmentsSection';
import Engagement from '../SignupsAndEngagement/Engagement';
import Signups from '../SignupsAndEngagement/Signups';
import Summary from '../Summary';
import TopContent from '../TopContent';
import { createFiles } from '../utils';
// Components
import messages from './messages';
import {
	Container,
	HelpMessage,
	IncreaseCoverageContainer,
	IncreaseCoverageHeader,
	ModalText,
	SecondaryHeader,
	StyledBanner,
} from './styles';

const CONTENT_FF = 'b2b-content-widget';
export const B2B_UNLIMITED_SEGMENTS = 'b2b-unlimited-segments';

function getNextSyncTime(): string {
	const time = new Date();
	// B2B Segmented Reporting syncs each day at 12AM UTC & 12PM UTC.
	const nextSync = time.getUTCHours() >= 12 ? 0 : 12;

	return new Date(time.setUTCHours(nextSync, 0))
		.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
		.replace(' ', '')
		.toLowerCase();
}

function searchToTimeframe(search: string): {
	value: Key;
	startDate: Date;
	endDate: Date;
} {
	const today = DateTime.local();
	const yesterday = today.minus({ days: 1 }).toJSDate() as Date;
	const twelveWeeksAgo = today.minus({ weeks: 12 }).toJSDate() as Date;

	const query = querystring.parse(search);
	const timeframe = query.timeframe;
	const startDate = query.startDate
		? !isNaN(Number(query.startDate as string))
			? parseInt(query.startDate as string)
			: dateFromIso8601DateForDatePicker(query.startDate as string)
		: '';
	const endDate = query.endDate
		? !isNaN(Number(query.endDate as string))
			? parseInt(query.endDate as string)
			: dateFromIso8601DateForDatePicker(query.endDate as string)
		: '';

	if (timeframe) {
		switch (timeframe) {
			case timeframeValues.SEVEN_DAYS:
				const sevenDaysAgo = today.minus({ days: 7 }).toJSDate() as Date;
				return {
					value: timeframe,
					startDate: sevenDaysAgo,
					endDate: yesterday,
				};
			case timeframeValues.TWENTY_EIGHT_DAYS:
				const twentyEightDaysAgo = today.minus({ days: 28 }).toJSDate() as Date;
				return {
					value: timeframe,
					startDate: twentyEightDaysAgo,
					endDate: yesterday,
				};
			case timeframeValues.TWELVE_WEEKS:
				const twelveWeeksAgo = today.minus({ weeks: 12 }).toJSDate() as Date;
				return {
					value: timeframe,
					startDate: twelveWeeksAgo,
					endDate: yesterday,
				};
			case timeframeValues.YEAR:
				const oneYearAgo = today.minus({ years: 1 }).toJSDate() as Date;
				return {
					value: timeframe,
					startDate: oneYearAgo,
					endDate: yesterday,
				};
		}
	}
	if (startDate && endDate)
		return { value: timeframeValues.CUSTOM, startDate: new Date(startDate), endDate: new Date(endDate) };
	return { value: timeframeValues.TWELVE_WEEKS, startDate: twelveWeeksAgo, endDate: yesterday };
}

function getStartDate(endDateToUse: Date | undefined, timeframe: Key): Date | undefined {
	if (!endDateToUse) return undefined;
	const endDate = DateTime.fromJSDate(endDateToUse);
	if (timeframe) {
		switch (timeframe) {
			case timeframeValues.SEVEN_DAYS:
				const sevenDaysAgo = endDate.minus({ days: 6 }).toJSDate() as Date;
				return sevenDaysAgo;
			case timeframeValues.TWENTY_EIGHT_DAYS:
				const twentyEightDaysAgo = endDate.minus({ days: 27 }).toJSDate() as Date;
				return twentyEightDaysAgo;
			case timeframeValues.TWELVE_WEEKS:
				const twelveWeeksAgo = endDate.minus({ weeks: 11, days: 6 }).toJSDate() as Date;
				return twelveWeeksAgo;
			case timeframeValues.YEAR:
				const oneYearAgo = endDate.plus({ days: 1 }).minus({ years: 1 }).toJSDate() as Date;
				return oneYearAgo;
			default:
		}
	}
	return undefined;
}

const DownloadingProgressOverlay: React.FC<{ percentComplete: number }> = ({ percentComplete }) => {
	const { formatMessage } = useIntl();
	const downloadProgressLabel = 'download-progress-label';

	return (
		<Modal
			isOpen
			canClose={false}
			closeModal={noop}
			data-testid="download-modal"
			aria-label="Download report modal"
		>
			<ModalText id={downloadProgressLabel}>{formatMessage(messages.downloading)}</ModalText>
			<ProgressBar percentComplete={percentComplete} aria-labelledby={downloadProgressLabel} />
		</Modal>
	);
};

export default function B2BReporting({ partner }: { partner: Partner }): ReactElement {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();
	const history = useHistory();
	const { pathname, search, hash } = useLocation();
	const timeframeDefault = searchToTimeframe(search);
	const [showModal, setShowModal] = useState<boolean>(false);
	const downloadImmediately = hash === '#download';
	const [didDownloadImmediately, setDidDownloadImmediately] = useState(false);
	const [isDownloadLoading, setIsDownloadLoading] = useState(false);
	const [downloadProgress, setDownloadProgress] = useState(0);
	const [selectedLongerTimeframe, setSelectedLongerTimeframe] = useState<{
		value: Key;
		startDate: Date;
		endDate: Date;
	}>(timeframeDefault);
	const [startDate, setStartDate] = useState<Date | undefined>();
	const [endDate, setEndDate] = useState<Date | undefined>();
	const partnerId = partner.id;
	useRecordMilestoneOnLoad(partnerId, PartnerMilestoneType.REPORTING_VIEWED);

	const zip = useMemo(() => new JSZip(), []);
	const segmentRef = useRef<HTMLDivElement | null>(null);
	const summaryRef = useRef<HTMLDivElement | null>(null);
	const feedbackRef = useRef<HTMLDivElement | null>(null);
	const signupRef = useRef<HTMLDivElement | null>(null);
	const engagementRef = useRef<HTMLDivElement | null>(null);
	const moodRef = useRef<HTMLDivElement | null>(null);
	const portraitsRef = useRef<HTMLDivElement | null>(null);
	const topContentRef = useRef<HTMLDivElement | null>(null);
	const contentRef = useRef<HTMLDivElement | null>(null);
	const hourlyUsageRef = useRef<HTMLDivElement | null>(null);

	const { data: flagValues } = useFeatureFlags(CONTENT_FF, B2B_UNLIMITED_SEGMENTS);
	const canSeeContent = flagValues && flagValues[CONTENT_FF] === true;
	const useLocalStorage = flagValues && flagValues[B2B_UNLIMITED_SEGMENTS] === true;

	const segmentStorageKey = `reporting:segments:${partnerId}`;
	const localStorageItem = sessionStorage.getItem(segmentStorageKey);
	const segmentsSelectedParams = useMemo(() => paramsToSegments(search), [search]);
	const segmentsSelectedStorage = useMemo(() => paramsToSegments(localStorageItem ?? ''), [localStorageItem]);
	const searchParamsExist =
		segmentsSelectedParams.segment1 || segmentsSelectedParams.segment2 || segmentsSelectedParams.segment3;
	const segmentsSelected = useLocalStorage
		? searchParamsExist
			? segmentsSelectedParams
			: segmentsSelectedStorage
		: segmentsSelectedParams;

	const {
		data: endDateData,
		error: endDateError,
		loading: endDateLoading,
	} = useMostRecentEndDate({
		partnerId,
		segmentSelection: segmentsSelected,
	});

	const endDateToUse = useMemo(() => {
		if (endDateError) {
			return selectedLongerTimeframe.endDate;
		}
		if (endDateLoading || !endDateData) {
			return undefined;
		}
		if (selectedLongerTimeframe.value === timeframeValues.CUSTOM) {
			if (selectedLongerTimeframe.endDate < dateFromIso8601DateForDatePicker(endDateData)) {
				return selectedLongerTimeframe.endDate;
			}
		}
		return dateFromIso8601DateForDatePicker(endDateData);
	}, [
		endDateData,
		endDateError,
		endDateLoading,
		selectedLongerTimeframe.endDate,
		selectedLongerTimeframe.value,
	]);

	const startDateToUse = useMemo(() => {
		return getStartDate(endDateToUse, selectedLongerTimeframe.value) || selectedLongerTimeframe.startDate;
	}, [endDateToUse, selectedLongerTimeframe]);

	const signupsAndEngagementReport = useSignupsAndEngagement({
		partnerId,
		startDate: startDateToUse,
		endDate: endDateToUse,
		segmentSelection: segmentsSelected,
	});

	const moodReport = useMoodReport({
		partnerId,
		startDate: startDateToUse,
		endDate: endDateToUse,
		segmentSelection: segmentsSelected,
	});

	const portraitsReport = usePortraits({
		partnerId,
		startDate: startDateToUse,
		endDate: endDateToUse,
		segmentSelection: segmentsSelected,
	});

	const {
		data: topContentReport,
		error: topContentError,
		loading: topContentLoading,
	} = useTopContent({
		partnerId,
		startDate: startDateToUse,
		endDate: endDateToUse,
		segmentSelection: segmentsSelected,
	});

	const {
		data: sessionReport,
		error: sessionError,
		loading: sessionLoading,
	} = useSessionsReport({
		partnerId,
		startDate: startDateToUse,
		endDate: endDateToUse,
		segmentSelection: segmentsSelected,
	});

	const {
		data: contentReport,
		error: contentError,
		loading: contentLoading,
	} = useContentReport({
		partnerId,
		startDate: startDateToUse,
		endDate: endDateToUse,
		segmentSelection: segmentsSelected,
	});

	const { data: feedback, loading: feedbackLoading } = useFeedbackHighlighted(partnerId);
	const isShowingSegmented = checkIsShowingSegmented(segmentsSelected);
	const nextSyncTime = getNextSyncTime();
	const feedbackIgnoreProps = feedback?.feedback.length ? {} : { 'data-html2canvas-ignore': 'true' };
	const baseReport = signupsAndEngagementReport.data;

	useEffect(() => {
		if (isShowingSegmented) {
			logEvent('Partner Portal : Segmented Reporting : Viewed');
		}
	}, [logEvent, isShowingSegmented]);

	const _createFiles = useMemo(
		() =>
			createFiles(
				setIsDownloadLoading,
				setDownloadProgress,
				partner,
				summaryRef,
				feedbackRef,
				feedback,
				signupRef,
				engagementRef,
				moodRef,
				portraitsRef,
				contentRef,
				sessionReport,
				topContentRef,
				hourlyUsageRef,
				zip,
				segmentRef,
				dispatch,
			),
		[feedback, partner, sessionReport, zip, dispatch, moodRef, portraitsRef, contentRef],
	);

	const scrollToWidgetViaAnchor = useCallback((): void => {
		if (signupsAndEngagementReport.loading || moodReport.loading) return;
		if (hash === '#signup' || hash === '#signupRate') {
			signupRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
		if (hash === '#engagementRate' || hash === '#activelyEngaged') {
			engagementRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
		if (hash === '#mood' || hash === '#sentiment') {
			moodRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [hash, signupRef, engagementRef, moodRef, signupsAndEngagementReport.loading, moodReport.loading]);

	useEffect(() => {
		scrollToWidgetViaAnchor();
	}, [scrollToWidgetViaAnchor]);

	const exportWholePageToPDF = useCallback((): void => {
		_createFiles().catch((error: Error) => {
			dispatch(
				setBannerMessage({
					message: `Unable to download the report. Please try again.`,
					isError: true,
					flash: true,
				}),
			);
		});
		setIsDownloadLoading(true);
		logEvent('Partner Portal : Download Reporting PDF : Clicked');
	}, [_createFiles, dispatch, logEvent]);
	useEffect(() => {
		let timeoutId: NodeJS.Timeout | undefined = undefined;
		if (downloadImmediately && !didDownloadImmediately) {
			setDidDownloadImmediately(true);
			timeoutId = setTimeout(exportWholePageToPDF, 1000);
		}
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [didDownloadImmediately, downloadImmediately, exportWholePageToPDF]);

	const showSimpleBanner = !baseReport?.is_mocked && baseReport?.updated_at;

	const updateSegmentFilter = (state: SegmentSelectState): void => {
		const params = segmentsToParams(state, selectedLongerTimeframe);
		if (useLocalStorage) {
			sessionStorage.setItem(segmentStorageKey, params);
			history.push({
				pathname,
				search: segmentsToParams(
					{ segment1: undefined, segment2: undefined, segment3: undefined },
					selectedLongerTimeframe,
				),
			});
		} else {
			history.push({ pathname, search: params });
		}
	};

	const isSelfServe = isSelfServePlan(partner?.vouched_plan_sku);
	const coveredLives = partner?.contract_covered_lives;

	return (
		<ReportingContextProvider
			isDownloading={isDownloadLoading}
			selectedLongerTimeframe={selectedLongerTimeframe}
			setSelectedLongerTimeframe={setSelectedLongerTimeframe}
			startDate={startDate}
			setStartDate={setStartDate}
			endDate={endDate}
			endDateToUse={endDateToUse}
			setEndDate={setEndDate}
			isReportMocked={baseReport && baseReport.is_mocked}
			partner={partner}
			signupsAndEngagementReport={signupsAndEngagementReport}
			moodReport={moodReport}
			portraitsReport={portraitsReport}
			scrollToWidgetViaAnchor={scrollToWidgetViaAnchor}
			maxEndDate={endDateData}
			updateSegmentFilter={updateSegmentFilter}
		>
			{isDownloadLoading && <DownloadingProgressOverlay percentComplete={downloadProgress} />}
			<Container>
				<InitialPageAnalytics />
				<ReportingButtonContainer
					isSelfServe={isSelfServe}
					isDownloadLoading={isDownloadLoading}
					exportWholePageToPDF={exportWholePageToPDF}
					partnerId={partnerId}
					updateSegmentFilter={updateSegmentFilter}
					segmentsSelected={segmentsSelected}
					showModal={showModal}
					setShowModal={setShowModal}
					showDownloadButton={!isReportNotFoundError(signupsAndEngagementReport.error) && Boolean(baseReport)}
				/>
				{baseReport && (baseReport.is_mocked || !showSimpleBanner) && (
					<StyledBanner Icon={InfoOutline} backgroundColor={'transparent'} data-html2canvas-ignore="true">
						{baseReport.is_mocked && formatMessage(messages.mockedReport)}
						{baseReport.is_mocked && !showSimpleBanner ? ' ' : null}
						{!showSimpleBanner &&
							formatMessage(messages.reportingDelay, {
								nextSyncTime,
							})}
					</StyledBanner>
				)}
				<SegmentsSection
					ref={segmentRef}
					partnerId={partnerId}
					segmentsSelected={segmentsSelected}
					setShowModal={setShowModal}
				/>
				{isSelfServe && coveredLives && (
					<IncreaseCoverageContainer>
						<IncreaseCoverageHeader>
							{formatMessage(messages.tooFewUsersSelfServeMessage, { coveredLives })}
						</IncreaseCoverageHeader>
						<HelpMessage>To increase your coverage, you can do so here</HelpMessage>
						<HelpMessage>
							<IncreaseCoverage coveredLives={coveredLives} partnerId={partnerId}></IncreaseCoverage>
						</HelpMessage>
					</IncreaseCoverageContainer>
				)}
				<Summary ref={summaryRef} />
				<SecondaryHeader>Insights</SecondaryHeader>
				<div {...feedbackIgnoreProps}>
					<HighlightedFeedbackBox
						isLoading={feedbackLoading}
						ref={feedbackRef}
						data={feedback}
						partnerId={partnerId}
					/>
				</div>
				<Signups
					ref={signupRef}
					isPrivacyError={isReportingPrivacyError(signupsAndEngagementReport.error)}
					isNoDataError={isReportNotFoundError(signupsAndEngagementReport.error)}
					isShowingSegmented={isShowingSegmented}
				/>
				<Engagement
					ref={engagementRef}
					isPrivacyError={isReportingPrivacyError(signupsAndEngagementReport.error)}
					isNoDataError={isReportNotFoundError(signupsAndEngagementReport.error)}
				/>
				<MoodCheckin
					ref={moodRef}
					isPrivacyError={isReportingPrivacyError(moodReport.error)}
					isNoDataError={isReportNotFoundError(moodReport.error)}
				/>

				<Portraits
					ref={portraitsRef}
					isPrivacyError={isReportingPrivacyError(portraitsReport.error)}
					isNoDataError={isReportNotFoundError(portraitsReport.error)}
				/>
				{canSeeContent && (
					<Content
						ref={contentRef}
						contentReport={contentReport}
						isLoading={contentLoading}
						isPrivacyError={isReportingPrivacyError(contentError)}
						isNoDataError={isReportNotFoundError(contentError)}
					/>
				)}
				<TopContent
					ref={topContentRef}
					isLoading={topContentLoading || sessionLoading}
					isPrivacyError={isReportingPrivacyError(topContentError) || isReportingPrivacyError(sessionError)}
					isNoDataError={
						isReportNotFoundError(topContentError) ||
						!topContentReport ||
						!sessionReport ||
						isReportNotFoundError(sessionError)
					}
					isShowingSegmented={isShowingSegmented}
					sessionData={sessionReport?.sessions}
					contentData={topContentReport}
				/>
				{sessionReport?.sessions && (
					<div ref={hourlyUsageRef}>
						<HourlyUsage
							isLoading={sessionLoading}
							data={sessionReport.sessions}
							isPrivacyError={isReportingPrivacyError(sessionError)}
							isNoDataError={isReportNotFoundError(sessionError) || !sessionReport}
						/>
					</div>
				)}
			</Container>
		</ReportingContextProvider>
	);
}
