import { ReactElement, useMemo } from 'react';

import { Card, FormSelect } from '@calm-web/design-system';

import CellTitle, { Subtitle } from '@/components/ui/CellTitle';
import { EditHealthConfigFormProps } from '@/hooks/forms/useHealthConfigForm';

export enum EligibilityFileFormatType {
	DIRECT_DEFAULT = 'direct_default_v1',
	PAYOR_DEFAULT = 'payor_default_v1',
	SLIM = 'slim_v1',
	OPTUM = 'optum_v1',
	EVERNORTH = 'evernorth_v1',
}

function EligibilityFileFormat({
	formProps,
	isEdit,
}: {
	formProps: EditHealthConfigFormProps;
	isEdit: boolean;
}): ReactElement {
	const disabled =
		isEdit &&
		formProps.model.eligibility_file_format !== '' &&
		!formProps.dirtyState.eligibility_file_format?.hasChanged;

	const templateOptions: {
		value: string;
		label: string;
	}[] = useMemo(() => {
		const options = [
			{
				value: EligibilityFileFormatType.DIRECT_DEFAULT,
				label: 'Direct Default V1',
			},
			{
				value: EligibilityFileFormatType.SLIM,
				label: 'Slim V1',
			},
		];
		return isEdit
			? [
					...options,
					{
						value: EligibilityFileFormatType.OPTUM,
						label: 'Optum V1',
					},
					{
						value: EligibilityFileFormatType.EVERNORTH,
						label: 'Evernorth V1',
					},
			  ]
			: options;
	}, [isEdit]);

	return (
		<Card>
			<div>
				<CellTitle showAdminFlag id="eligibility-file-template-label">
					Eligibility File Template
				</CellTitle>
				<Subtitle id="eligibiltyFileTemplateLabel">
					{disabled
						? 'Reach out to Partner Portal support to make Template adjustments'
						: 'Select appropriate Eligbility File Template for Client:'}
				</Subtitle>
				<FormSelect
					options={templateOptions}
					{...formProps.bindWithErrorProps('eligibility_file_format', 'select')}
					placeholder="Choose a template"
					aria-labelledby="eligibiltyFileTemplateLabel"
					disabled={disabled}
				></FormSelect>
			</div>
		</Card>
	);
}

export default EligibilityFileFormat;
