import Image from 'next/image';
import { FC } from 'react';

import { CalmHealthDarkLogo } from '@calm-web/icons';

import { StyledLoader } from '@/components/pages/EapPromotion/styles';

import { PhoneOutline, Wrapper } from '../styles';
import {
	BannerBody,
	BannerTitle,
	CalmHealthAppIcon,
	CalmHealthLogoWrapper,
	Content,
	DownloadCalm,
	DownloadCalmCta,
	Hero,
	HeroFade,
	LogoBar,
	SignIn,
	ViewPort,
} from './styles';

interface Props {
	title: string;
	body: string;
}
const LandingPagePreview: FC<Props> = ({ title, body }) => {
	return (
		<Wrapper data-testid="landing-page-preview">
			<PhoneOutline />
			<ViewPort>
				<Hero>
					<LogoBar>
						<CalmHealthLogoWrapper>
							<CalmHealthDarkLogo width={96} height={42} />
						</CalmHealthLogoWrapper>
						<SignIn>Sign In</SignIn>
					</LogoBar>
					<StyledLoader color="gray5" />
					<Image
						src={'https://res.cloudinary.com/calm-com/image/upload/v1582139698/jasper-lake.jpg'}
						alt="Landscape of Jasper Lake"
						fill
					/>
					<HeroFade />
				</Hero>
				<Content>
					<BannerTitle>{title}</BannerTitle>
					<BannerBody>{body}</BannerBody>
					<DownloadCalm>
						<DownloadCalmCta>
							<CalmHealthAppIcon>
								<Image
									width={64}
									height={64}
									src="/_n/images/calm-health-app-logo.png"
									alt="The Calm Health App Icon"
								/>
							</CalmHealthAppIcon>
							Download the Calm Health Mobile App
						</DownloadCalmCta>
					</DownloadCalm>
				</Content>
			</ViewPort>
		</Wrapper>
	);
};

export default LandingPagePreview;
