/* eslint-disable @next/next/no-img-element */

import { ReactElement } from 'react';

import {
	ApiCode,
	ApiCodeHeader,
	ApiCodeResponse,
	ApiImg,
	ApiResponseBlob,
	ApiSectionHeader,
	ApiURL,
	CenteredContent,
	Content,
	ContentWrapper,
	Header,
	LandingImg,
	SectionBody,
	SectionHeader,
	Subtitle,
	Title,
	Updated,
} from '../styles';

export default function ApiIntegration(): ReactElement {
	return (
		<div>
			<Header>
				<img
					src="https://res.cloudinary.com/calm-com/image/upload/v1623175960/assets/images/logos/calm_square_logo.png"
					alt="Calm Logo"
				/>
				<Title>Partner API Integration</Title>
				<Updated>Last Updated: June 8, 2021</Updated>
			</Header>

			<ContentWrapper>
				<Content>
					<SectionHeader>Table of Contents</SectionHeader>
					<SectionBody>
						<ul>
							<li>
								<a href="#integration">Calm Partner API Integration</a>
							</li>
							<li>
								<a href="#how">How does the integration work?</a>
							</li>
							<li>
								<a href="#api">Calm Partner API</a>
							</li>
						</ul>
					</SectionBody>

					<SectionHeader id="integration">Calm Partner API Integration</SectionHeader>
					<SectionBody>
						<p>
							Partners can integrate with the Calm Partner API (hereinafter “Calm API”) to provision their
							users with a Calm subscription.
						</p>
						<p>
							Each unique user in a partner’s system (a “partner user”) is able to activate a single Calm
							subscription. Once activated, users will have access to the Calm app without additional
							onboarding steps.
						</p>
						<p>
							Calm’s B2B Engineering Team will support engineering efforts during onboarding, testing and push
							to production.
						</p>
					</SectionBody>

					<SectionHeader id="how">How does the integration work?</SectionHeader>
					<SectionBody>
						<p>
							Partners are provided with credentials (<code>client_secret, client_id</code>) to authenticate
							and acquire an access token (JWT) via the Calm API. This token is used in subsequent calls to
							provision new accounts and revoke account access.
						</p>
						<p>
							To create a new user account, the Calm API requires a GUID (<code>partner_user_id</code>). The
							Calm API will respond by redirecting the user to Calm’s website where they are prompted to login
							to an existing Calm account or create a new Calm account with the email and password of their
							choice.
						</p>
						<p>
							<CenteredContent>
								<LandingImg
									src="https://res.cloudinary.com/calm-com/image/upload/v1622070187/partner-portal/docs/landing.png"
									alt="Landing Page"
									width="600px"
								/>
								<Subtitle>(Calm.com landing page, indicates successful configuration)</Subtitle>
							</CenteredContent>
						</p>
						<p>
							If the user has an existing Calm account, they’re prompted to wait until their current
							subscription ends OR create a new Calm account with a different email address.
						</p>
						<p>
							After login or signup, the user will have activated their Calm subscription and have access to
							Calm through our Mobile Apps or at Calm.com.
						</p>
					</SectionBody>

					<SectionHeader id="api">Calm Partner Api</SectionHeader>
					<SectionBody>
						<p>
							API integration is a 2 step API process:
							<ul>
								<li>Authenticate partner service with Calm’s auth service</li>
								<li>Request to link a partner user to a Calm user account</li>
							</ul>
						</p>
						<ApiImg
							src="https://res.cloudinary.com/calm-com/image/upload/v1622149091/partner-portal/docs/Screen_Shot_2021-05-27_at_1.54.11_PM.png"
							alt="Calm API"
						/>
						<ApiSectionHeader>Calm Partner Authentication API</ApiSectionHeader>
						<p>
							To integrate with the Calm API in a secure manner, the partner service needs to authenticate and
							acquire an <code>access_token</code> (JWT) via Calm’s Auth service. Calm’s B2B Engineering Team
							will provision partners with credentials (<code>client_secret, client_id</code>) for development
							and production.
						</p>
						<ApiCode>
							<ApiCodeHeader>Request</ApiCodeHeader>
							<ApiCodeResponse>
								POST <ApiURL>https://auth.calm.com/v0/authorize</ApiURL>
							</ApiCodeResponse>
							<ApiCodeResponse>
								BODY
								<br />
								&#123;
								<ApiResponseBlob>
									<span>&quot;client_id&quot;: &quot;string&quot;,</span>
									<span>&quot;client_secret&quot;: &quot;string&quot;,</span>
									<span>&quot;grant_type&quot;: &quot;client_credentials&quot;,</span>
									<span>&quot;scope&quot;: &quot;b2b.users.integrate,b2b.subscription.cancel&quot;</span>
								</ApiResponseBlob>
								&#125;
							</ApiCodeResponse>

							<ApiCodeHeader>Response</ApiCodeHeader>
							<ApiCodeResponse>
								200 OK
								<br />
								&#123;
								<ApiResponseBlob>
									<span>&quot;access_token&quot;: &quot;eyJhbGciO...&quot;,</span>
									<span>&quot;token_type&quot;: &quot;Bearer&quot;,</span>
									<span>&quot;expires_at&quot;: 1571536335,</span>
									<span>&quot;token_id&quot;: &quot;string&quot;</span>
								</ApiResponseBlob>
								&#125;
							</ApiCodeResponse>

							<ApiCodeHeader>Errors</ApiCodeHeader>
							<ApiCodeResponse>
								403 Invalid authorization
								<br />
								403 Invalid scope
							</ApiCodeResponse>
						</ApiCode>

						<ApiSectionHeader>Calm Partner Subscription API</ApiSectionHeader>
						<p>
							With the <code>access_token</code> from the authentication step, the service can make a request
							to the Calm subscription service
						</p>
						<ApiCode>
							<ApiCodeHeader>Request</ApiCodeHeader>
							<ApiCodeResponse>
								POST <ApiURL>https://auth.calm.com/v0/b2b/users/link</ApiURL>
							</ApiCodeResponse>
							<ApiCodeResponse>
								HEADER
								<br />
								&emsp;Authorization: Bearer &#123;access_token&#125;
							</ApiCodeResponse>
							<ApiCodeResponse>
								BODY
								<br />
								&#123;
								<ApiResponseBlob>
									<span>&quot;partner_user_id&quot;: string (REQUIRED) - partner generated guid</span>
									<span>
										&quot;error_url&quot;: string (OPTIONAL) - on error, directs users to your custom help
										page
									</span>
								</ApiResponseBlob>
								&#125;
							</ApiCodeResponse>

							<ApiCodeHeader>Response</ApiCodeHeader>
							<ApiCodeResponse>
								303 REDIRECT
								<br />
								<br />
								HEADER
								<br />
								&emsp;Location:{' '}
								<ApiURL>
									https://www.calm.com/b2b/&#123;partner_id&#125;/subscribe?calm-link-token=&#123;token&#125;
								</ApiURL>
							</ApiCodeResponse>

							<ApiCodeHeader>Errors</ApiCodeHeader>
							<ApiCodeResponse>
								400 Invalid request
								<br />
								401 Unauthorized
							</ApiCodeResponse>
						</ApiCode>
						<p>The Location in the response depends on the partner user state:</p>
						<ul>
							<li>
								If the partner user doesn’t have a Calm subscription, the Calm API will redirect the user to{' '}
								<code>https://www.calm.com/b2b/&#123;partner_id&#125;/subscribe</code>. The subscribe page
								will guide the user through a Calm account or login flow, and activate a subscription to Calm
								for the user. The following query parameters are in the redirect URL
							</li>
							<ul>
								<li>calm-link-token</li>
								<li>support_url (your custom error_url, if provided)</li>
							</ul>
							<li>
								If the partner user already has an active Calm subscription, the user will be redirected to{' '}
								<code>https://www.calm.com/b2b/&#123;partner_id&#125;/access</code> which is a Calm landing
								page with the user logged in. The user is shown a deep link to the Calm app on mobile or
								desktop. The following query parameters are in the redirect URL:{' '}
							</li>
							<ul>
								<li>calm-link-token</li>
								<li>user-id (calm user-id)</li>
								<li>support-url (your custom error_url, if provided)</li>
							</ul>
							<li>
								If the partner user subscription has expired, and cannot be renewed, the user is redirected to{' '}
								<code>https://www.calm.com/b2b/&#123;partner_id&#125;/expired</code>. Depending on the
								partnership with Calm, this page may show the next step for users to purchase a discounted
								subscription. support-url (your custom error_url, if provided)
							</li>
						</ul>
						<p>
							<ApiURL>NOTE</ApiURL>: that the above response is a HTTP status 303 REDIRECT that will be
							handled automatically by most browsers correctly as a redirect. To receive the redirect in the
							HTTP response body, optionally add the query param pseudo_redirect=1 in the request URL.
						</p>
						<ApiCode>
							<ApiCodeHeader>Request</ApiCodeHeader>
							<ApiCodeResponse>
								POST <ApiURL>https://auth.calm.com/v0/b2b/users/link?pseudo_redirect=1</ApiURL>
							</ApiCodeResponse>
							<ApiCodeResponse>
								HEADER
								<br />
								&emsp;Authorization: Bearer &#123;access_token&#125;
							</ApiCodeResponse>
							<ApiCodeResponse>
								BODY
								<br />
								&#123;
								<ApiResponseBlob>
									<span>&quot;partner_user_id&quot;: string (REQUIRED) - partner generated guid</span>
								</ApiResponseBlob>
								&#125;
							</ApiCodeResponse>

							<ApiCodeHeader>Response</ApiCodeHeader>
							<ApiCodeResponse>
								200 OK
								<br />
								&#123;
								<ApiResponseBlob>
									<span>
										&quot;redirect_location&quot;: string (REQUIRED) - contains calm-link-token and user-id,
									</span>
								</ApiResponseBlob>
								&#125;
							</ApiCodeResponse>
						</ApiCode>

						<ApiSectionHeader>Calm Partner Authentication API</ApiSectionHeader>
						<p>
							Cancel a partner user subscription using the <code>partner_user_id</code>. When the API is
							called, the user’s subscription will not auto renew if auto renew is turned on. The subscription
							will end on the last day of the current renewable period. If there is no renewal, making this
							call would cause no changes.
						</p>
						<ApiCode>
							<ApiCodeHeader>Request</ApiCodeHeader>
							<ApiCodeResponse>
								DELETE <ApiURL>https://auth.calm.com/v0/b2b/users/&#123;partner_user_id&#125;</ApiURL>
							</ApiCodeResponse>
							<ApiCodeResponse>
								HEADER
								<br />
								&emsp;Authorization: Bearer &#123;access_token&#125;
							</ApiCodeResponse>
							<ApiCodeHeader>Response</ApiCodeHeader>
							<ApiCodeResponse>
								200 OK
								<br />
								<br />
								<ApiCodeResponse>
									BODY
									<br />
									&#123;
									<ApiResponseBlob>
										<span>&quot;partner_user_id&quot;: string</span>
										<span>&quot;calm_user_id&quot;: string - only present if status is canceled</span>
										<span>
											&quot;expires&quot;: string - expiration timestamp, only present if status is canceled
										</span>
										<span>&quot;status&quot;: [&quot;canceled&quot; | &quot;not_found&quot;]</span>
									</ApiResponseBlob>
									&#125;
								</ApiCodeResponse>
							</ApiCodeResponse>

							<ApiCodeHeader>Errors</ApiCodeHeader>
							<ApiCodeResponse>401 Unauthorized</ApiCodeResponse>
						</ApiCode>

						<ApiSectionHeader>Environment Configuration</ApiSectionHeader>
						<p>Calm Partner APIs are available in two separate environments:</p>
						<table>
							<tr>
								<th>ENV</th>
								<th>Description</th>
								<th>Calm Partner API domain</th>
							</tr>
							<tr>
								<td>DEVELOPMENT</td>
								<td>For development, integration testing</td>
								<td>https://auth-ga.aws-dev.useast1.calm.com</td>
							</tr>
							<tr>
								<td>PRODUCTION</td>
								<td>Live production system</td>
								<td>https://auth.calm.com</td>
							</tr>
						</table>
					</SectionBody>
				</Content>
			</ContentWrapper>
		</div>
	);
}
