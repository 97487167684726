import _ from 'lodash';
import querystring from 'query-string';
import { FC, ReactElement, ReactNode, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { mutate } from 'swr';

import {
	Button,
	FontSizes,
	FontWeights,
	FormInput,
	IconButton,
	Loader,
	PrimaryButton,
	Text,
	Tooltip,
} from '@calm-web/design-system';
import { BackArrow } from '@calm-web/icons';
import { OnChange } from '@calm-web/use-form';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import { StyledBackLink } from '@/components/ui/BackLink/styles';
import CellContainer from '@/components/ui/CellContainer';
import IncreaseCoverage from '@/components/ui/IncreaseCoverage';
import MobilePaginator from '@/components/ui/MobilePaginator';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { ApiResponse } from '@/hooks/api/types';
import { useDownloadEligibilityCsv, useEligibility, useEligibilityUploads } from '@/hooks/api/useEligibility';
import { UploadResults, useUploadEligibilityFile } from '@/hooks/api/useEligibilityFile';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { useIntegrationType } from '@/hooks/api/useIntegrationType';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { usePartnerStats } from '@/hooks/api/usePartnerStats';
import { usePartnerPathways } from '@/hooks/api/usePathways';
import useSegments, { Segments } from '@/hooks/api/useSegments';
import { useCalmHealthSftpIntegration } from '@/hooks/api/useSftpConfig';
import { useUploadSftpEligibilityFile } from '@/hooks/api/useSftpEligibilityUpload';
import { usePermissions } from '@/hooks/auth';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { useUser } from '@/hooks/store';
import { useShouldShowBenefitEmailUpdates } from '@/hooks/useShouldShowBenefitEmailUpdates';
import { useShouldShowUserSignupsPercentage } from '@/hooks/useShouldShowUserSignupsPercentage';
import { Eligibility, Emails, SegmentValues } from '@/types/eligibility';
import { IntegrationType, Partner } from '@/types/store/reducers';
import { ApiError } from '@/utils/apiRequest/errors';
import { formatDate } from '@/utils/helpers';
import * as PartnerUtils from '@/utils/partner';
import { isCalmHealthProductSKU, isSelfServePlan } from '@/utils/SkuUtils';
import DownloadOutline from 'icons/download-outline.svg';
import Search from 'icons/user-search.svg';

import AccountNavLink from '../Account/SubNav/AccountNavLink';
import { StyledNav } from '../Account/SubNav/styles';
import AddModal from './AddModal';
import DeleteModal from './DeleteModal';
import { LogsTable } from './Eligibility';
import LogDetails from './Eligibility/Details';
import EligibilityExceededModal from './EligibilityExceededModal';
import EmailReminderModal from './EmailReminderModal';
import LastUploadInfo from './LastUploadInfo';
import messages from './messages';
import PathwaysSegmentChangeModal from './PathwaysSegmentChangeModal';
import {
	BottomHeaderContainer,
	ButtonCell,
	ButtonGroup,
	CircleMinusStyled,
	CoverageWrapper,
	EmailReminderCta,
	EmailWrapper,
	ErrorDisplay,
	HeaderContainer,
	HeaderRow,
	Headline,
	HeadlinedWrapper,
	InfoIcon,
	MobileDataColumn,
	MobileLabel,
	MobileRow,
	MobileWrapper,
	ReminderEmailDate,
	Row,
	SearchWrapper,
	TableContainer,
	TableStyled,
	UsersTableCard,
} from './styles';
import SyncButton from './SyncButton';
import UploadEligibilityFile from './UploadEligibilityFile';
import UploadFirstEligibilityFile from './UploadFirstEligibilityFile';
import UploadSuccessModal from './UploadSuccessModal';

const PAGE_SIZE = 10;

function downloadButtonText(formatMessage: IntlShape['formatMessage'], error: unknown): string {
	if (error) {
		return formatMessage(messages.downloadButtonTextError);
	}

	return formatMessage(messages.downloadButtonText);
}

export function DownloadEligibilityButton({ partnerId }: { partnerId: string }): ReactElement {
	const { formatMessage } = useIntl();
	const [downloadEligibilityCsv, { loading, error }] = useDownloadEligibilityCsv(partnerId);

	return (
		<Button
			textColor="blue3"
			backgroundColor="transparent"
			hideShadow
			onPress={(): Promise<void> => downloadEligibilityCsv()}
			isLoading={loading}
			isDisabled={loading}
			data-testid="ef-download-button"
			Icon={DownloadOutline}
		>
			{downloadButtonText(formatMessage, error)}
		</Button>
	);
}

interface Props {
	partner: Partner;
}

function CreatedCell({ value }: { value: string }): ReactElement {
	const formattedDate = new Date(value).toLocaleString();
	return <>{formattedDate.toLowerCase()}</>;
}

const HeadlinedBlock: FC<{
	headline: string;
	stat?: number | string;
	loading?: boolean;
	showPercentSign?: boolean;
	children?: ReactElement;
	tooltipContent?: ReactElement | null;
}> = ({ headline, stat, loading, showPercentSign, tooltipContent, children }) => (
	<HeadlinedWrapper>
		<Row>
			<Headline>{headline}</Headline>
			{tooltipContent && (
				<Tooltip content={tooltipContent}>
					<InfoIcon />
				</Tooltip>
			)}
		</Row>
		{stat !== undefined &&
			(loading ? (
				<Loader color="gray1" data-testid="loader" />
			) : (
				<Text lineHeight={1} size={FontSizes['2xl']} weight={FontWeights.Medium} noMargin color="offBlack">
					{showPercentSign ? `${stat}%` : stat}
				</Text>
			))}
		{children ? children : null}
	</HeadlinedWrapper>
);

const RemoveUser = ({
	id,
	partnerUserId,
	setCurrentUserForDeletion,
}: {
	id: number;
	partnerUserId: string;
	setCurrentUserForDeletion: ({ id, partnerUserId }: { id: number; partnerUserId: string }) => void;
}): ReactElement => {
	const { logEvent } = useAnalytics();
	const [isHovered, setIsHovered] = useState(false);

	function handleClick(): void {
		logEvent('Partner Portal : Delete User : Clicked', {
			user_id: id,
		});
		setCurrentUserForDeletion({ id, partnerUserId });
	}

	return (
		<Tooltip placement="right" visible={isHovered} content="Remove user">
			<ButtonCell onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
				<IconButton onPress={handleClick} aria-label="Remove user" Icon={CircleMinusStyled} />
			</ButtonCell>
		</Tooltip>
	);
};

function customRound(num: number, places?: number): number {
	const decimalPlaces = Math.pow(10, places || 3);
	return Math.round(num * decimalPlaces + Number.EPSILON) / decimalPlaces;
}

export function TopHeaderContainer({
	partner,
	totalEligibleUsers = 0,
	totalRedeemed = 0,
	emailData,
	loadingEligibility,
}: {
	partner: Partner;
	loadingEligibility: boolean;
	totalRedeemed?: number;
	totalEligibleUsers?: number;
	emailData?: Emails;
}): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const [reminderModalOpen, setReminderModalOpen] = useState(false);
	const isSelfServe = isSelfServePlan(partner.vouched_plan_sku);
	const totalCoveredLives = partner.contract_covered_lives;
	const signUpQuotient = customRound(totalRedeemed / totalCoveredLives);
	const signupPercent = customRound(signUpQuotient * 100);
	// For privacy reasons, only show signup Percent when at least 10 users are signed up.
	const showSignupPercent = totalRedeemed >= 10;
	const canSendEmails = partner.email_config_can_send_benefit_reminders;
	const usesEmails = PartnerUtils.usesEmailAsDescriptor(partner);
	const shouldShowUserSignupsPercentage = useShouldShowUserSignupsPercentage();
	const shouldShowBenefitEmailUpdates = useShouldShowBenefitEmailUpdates();

	const handleSendReminderClick = (): void => {
		logEvent('Partner Portal : Reminder Email Modal : Opened');
		setReminderModalOpen(true);
	};

	return (
		<HeaderRow $isSelfServe={isSelfServe}>
			<HeadlinedWrapper>
				<HeadlinedBlock
					headline={formatMessage(messages.usersUploadedHeader)}
					stat={totalEligibleUsers.toLocaleString()}
					loading={loadingEligibility}
				>
					<LastUploadInfo partnerId={partner.id} />
				</HeadlinedBlock>
				{isSelfServe && (
					<CoverageWrapper>
						<IncreaseCoverage partnerId={partner.id} coveredLives={partner.contract_covered_lives} />
					</CoverageWrapper>
				)}
			</HeadlinedWrapper>
			{shouldShowUserSignupsPercentage && (
				<HeadlinedBlock
					headline={formatMessage(messages.signUpsHeader)}
					stat={showSignupPercent ? signupPercent : '--'}
					showPercentSign
					loading={loadingEligibility}
					tooltipContent={
						<Text el="span" size={FontSizes.sm}>
							{showSignupPercent
								? formatMessage(messages.signUpsExplanation)
								: formatMessage(messages.signUpsPrivacyNotice)}
						</Text>
					}
				/>
			)}
			<HeadlinedBlock
				headline={formatMessage(messages.signUpRemindersHeader)}
				tooltipContent={
					usesEmails && canSendEmails ? (
						<Text el="span" size={FontSizes.sm}>
							{formatMessage(messages.frequencyHeader)}
							<ul>
								<li>
									{shouldShowBenefitEmailUpdates
										? formatMessage(messages.frequencyOneDayAfterEmail)
										: formatMessage(messages.frequencyOneWeekAfterEmail)}
								</li>
								<li>{formatMessage(messages.frequencyOneMonthLaterEmail)}</li>
								<li>{formatMessage(messages.frequencyTwoMonthsLaterEmail)}</li>
							</ul>
						</Text>
					) : null
				}
			>
				{canSendEmails ? (
					<>
						{usesEmails ? (
							<>
								<Text el="span" size={FontSizes.sm} color="gray7">
									{formatMessage(messages.lastEmailSent)}{' '}
								</Text>
								<ReminderEmailDate>
									{(emailData?.most_recent_email && formatDate(new Date(emailData?.most_recent_email))) || (
										<i>{formatMessage(messages.noPreviouslySentEmail)}</i>
									)}
								</ReminderEmailDate>
								<Text el="span" size={FontSizes.sm} color="gray7">
									{formatMessage(messages.nextEmailSend)}{' '}
								</Text>
								<ReminderEmailDate>
									{(emailData?.next_email && formatDate(new Date(emailData?.next_email))) || (
										<i>{formatMessage(messages.noFutureEmails)}</i>
									)}
								</ReminderEmailDate>
								<Text el="p" size={FontSizes.sm} color="gray7" noMargin>
									{formatMessage(messages.manualReminderCopy, {
										sendEmailLink: (...chunks: ReactNode[]) => (
											<EmailReminderCta
												el="span"
												color="blue3"
												size={FontSizes.sm}
												role="button"
												tabIndex={0}
												onClick={handleSendReminderClick}
												onKeyDown={e => e.key === 'Enter' && handleSendReminderClick()}
											>
												{chunks}
											</EmailReminderCta>
										),
									})}
								</Text>

								<EmailReminderModal
									isOpen={reminderModalOpen}
									onClose={() => setReminderModalOpen(false)}
									partnerName={partner.name}
									partnerId={partner.id}
								/>
							</>
						) : (
							<Text el="span" size={FontSizes.sm} color="gray7">
								{formatMessage(messages.remindersRequireEmails, {
									italic: (...chunks: ReactNode[]) => <i>{chunks}</i>,
								})}
							</Text>
						)}
					</>
				) : (
					<Text el="span" size={FontSizes.sm}>
						{formatMessage(messages.unableToSendEmails, {
							i: (...chunks: ReactNode[]) => <i>{chunks}</i>,
							link: (...chunks: ReactNode[]) => <Link to={`account#emailPreferences`}>{chunks}</Link>,
						})}
					</Text>
				)}
			</HeadlinedBlock>
		</HeaderRow>
	);
}

interface UsersTableProps {
	partner: Partner;
	searchValue: string;
	pageIndex: number;
	setPageIndex: (n: number) => void;
	upload: (file: File) => Promise<ApiResponse<UploadResults>>;
	uploadLoading: boolean;
	uploadError?: Error | ApiError;
	setCurrentUserForDeletion: ({ id, partnerUserId }: { id: number; partnerUserId: string }) => void;
	showUploadModal: boolean;
	setShowUploadModal: (val: boolean) => void;
	onUploadSuccess: () => void;
}

function UsersTable({
	partner,
	searchValue,
	pageIndex,
	setPageIndex,
	showUploadModal,
	setShowUploadModal,
	onUploadSuccess,
	upload,
	uploadLoading,
	uploadError,
	setCurrentUserForDeletion,
	segments,
}: UsersTableProps & { segments: Segments }): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const [isMobile] = useIsMobile();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const UserRow = ({ user }: { user: Record<string, any> }): ReactElement => (
		<MobileRow>
			<MobileDataColumn>
				<MobileLabel>Unique Identifier</MobileLabel>
				<p>{user.partner_user_id}</p>
			</MobileDataColumn>
			{Object.values(segments).map((value, index) => (
				<MobileDataColumn key={value}>
					<MobileLabel>{value}</MobileLabel>
					<p>{user[`segment_${index + 1}_value`]}</p>
				</MobileDataColumn>
			))}
			<MobileDataColumn>
				<MobileLabel>Created At</MobileLabel>
				<CreatedCell value={user.created_at} />
			</MobileDataColumn>
			<MobileDataColumn>
				<MobileLabel>Remove User</MobileLabel>
				<RemoveUser
					id={user.id}
					partnerUserId={user.partner_user_id}
					setCurrentUserForDeletion={setCurrentUserForDeletion}
				/>
			</MobileDataColumn>
		</MobileRow>
	);

	const columns: Array<Column<Eligibility>> = useMemo(() => {
		const actionsCell = {
			accessor: 'id' as const,
			width: 50,
			Cell: ({ row: { values }, value }: CellProps<Eligibility, Eligibility['id']>): ReactElement => (
				<RemoveUser
					id={Number(value)}
					partnerUserId={values.partner_user_id}
					setCurrentUserForDeletion={setCurrentUserForDeletion}
				/>
			),
		};
		const userIdCell = {
			Header: 'Unique Identifier',
			accessor: 'partner_user_id' as const,
			Cell: ({ value }: { value: string }): ReactElement => <EmailWrapper>{value}</EmailWrapper>,
			width: 300,
		};
		const createdCell = {
			Header: 'Created',
			accessor: 'created_at' as const,
			Cell: CreatedCell,
			maxWidth: 150,
		};

		const segmentCells = Object.entries(segments)
			.filter(([, value]) => Boolean(value))
			.map(([key, value]) => {
				// We receive segment names from the segments endpoint, but eligibility rows contain segment values.
				// To get the right value corresponding to a name, we need to convert from segment_n_name
				// to segment_n_value.
				const accessor = key.replace('name', 'value') as keyof SegmentValues;
				return {
					Header: value as string,
					accessor,
					maxWidth: 150,
				};
			});

		return [userIdCell, ...segmentCells, createdCell, actionsCell];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partner, segments]);

	const { data: stats, loading: statsLoading, error: statsError } = usePartnerStats(partner?.id, searchValue);
	const {
		loading,
		data: eligibilityData,
		error,
	} = useEligibility(partner.id, pageIndex, PAGE_SIZE, searchValue);
	const users = eligibilityData?.eligibilities || [];
	const pageCount = Math.max(1, Math.ceil((stats?.numCoveredLives || 0) / PAGE_SIZE));

	function handleCancelClick(): void {
		setShowUploadModal(false);
	}

	const modal = showUploadModal ? (
		<UploadEligibilityFile
			partner={partner}
			isFirstUpload={!users.length && searchValue === ''}
			loading={uploadLoading}
			upload={upload}
			uploadError={uploadError}
			isModal={true}
			onClickCancel={handleCancelClick}
			onUploadSuccess={onUploadSuccess}
		/>
	) : null;

	if (statsError) {
		return null;
	}

	return (
		<CellContainer>
			<UsersTableCard>
				{isMobile ? (
					<>
						<MobileWrapper>
							{users.map(user => (
								<UserRow key={user.id} user={user} />
							))}
						</MobileWrapper>
						<MobilePaginator
							fetchData={({ pageIndex: newPageIndex }: { pageIndex: number }): void =>
								setPageIndex(newPageIndex)
							}
							loading={loading || statsLoading}
							pageCount={pageCount}
							error={error && formatMessage(messages.eligibleUsersErrorMessage)}
							pageSize={PAGE_SIZE}
						/>
					</>
				) : (
					<TableStyled
						columns={columns}
						data={users}
						fetchData={({ pageIndex: newPageIndex }: { pageIndex: number }): void =>
							setPageIndex(newPageIndex)
						}
						loading={loading || statsLoading}
						pageCount={pageCount}
						error={error && formatMessage(messages.eligibleUsersErrorMessage)}
						pageSize={PAGE_SIZE}
						cellVerticalAlign="middle"
						alignHeadingsToText
						cellNoPadding={isMobile}
					/>
				)}
			</UsersTableCard>
			{modal}
		</CellContainer>
	);
}

function UsersTableContainer(props: UsersTableProps): ReactElement {
	const {
		partner: { id },
	} = props;
	const { data: segments, loading, error } = useSegments(id);
	if (loading) {
		return <Loader color="gray1" data-testid="loader" />;
	}
	if (!segments || error) {
		return <ErrorDisplay>Unable to load users</ErrorDisplay>;
	}

	return <UsersTable {...props} segments={segments} />;
}

export function UsersContent({ partner }: Props): ReactElement {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const { data: flagValues } = useFeatureFlags('b2b-eligibility-file-exceeded-modal');
	const eligibilityFileExceededModalEnabled =
		flagValues && flagValues['b2b-eligibility-file-exceeded-modal'] === true;
	const [pageIndex, setPageIndex] = useState(0);
	const [showUploadSuccessModal, setShowUploadSuccessModal] = useState(false);
	const [hasValidPermissions, actions] = usePermissions();
	const shouldShowAddButton = hasValidPermissions('add_users_to_eligibility', [actions.UPDATE]);
	const [upload, uploadOpts] = useUploadEligibilityFile(partner.id);
	const { data: pathways } = usePartnerPathways(partner.id);
	const { data: uploadData, loading: uploadLoading, error: uploadError } = uploadOpts;
	const {
		addedCount: added = 0,
		deletedCount: removed = 0,
		duplicateCount: duplicates = 0,
		addedSegmentCount = 0,
		deletedSegmentCount = 0,
	} = uploadData ?? {};

	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showPathwaysSegmentChangeModal, setShowPathwaysSegmentChangeModal] = useState(false);
	const [showExceededModal, setShowExceededModal] = useState(false);
	const [currentUserForDeletion, setCurrentUserForDeletion] = useState<{
		id: number;
		partnerUserId: string;
	} | null>(null);
	const [searchValue, setSearchValue] = useState('');

	const {
		loading,
		data: eligibilityData,
		error,
	} = useEligibility(partner.id, pageIndex, PAGE_SIZE, searchValue);
	const users = eligibilityData?.eligibilities || [];
	const totalEligibleUsers = eligibilityData?.meta?.pagination.unfiltered_total || users?.length;
	const totalRedeemed = eligibilityData?.unfiltered_redeemed_total || 0;
	const shouldShowPathwaysSegmentChangeModal = Boolean(addedSegmentCount > 0 && !_.isEmpty(pathways));

	const onSearchChange: OnChange = e => {
		setSearchValue(e.target.value);
		setPageIndex(0);
	};

	function onUploadSuccess(): void {
		setShowUploadModal(false);
		setShowUploadSuccessModal(true);
	}

	async function onDeleteSuccess(): Promise<void> {
		setCurrentUserForDeletion(null);
	}

	async function onAddSuccess(): Promise<void> {
		setShowAddModal(false);
		await mutate(`b2b/partners/${partner.id}/eligibility-uploads`);
	}

	function canShowStats(): boolean {
		return (
			Boolean(added) ||
			Boolean(removed) ||
			Boolean(duplicates) ||
			Boolean(addedSegmentCount) ||
			Boolean(deletedSegmentCount)
		);
	}

	function openUploadModal(isFirstUpload: boolean): void {
		logEvent('Partner Portal : Upload File : Clicked', {
			is_first_upload: isFirstUpload,
		});
		setShowUploadModal(true);
	}

	function openAddModal(): void {
		logEvent('Partner Portal : Add User : Clicked');
		if (shouldShowAddButton) {
			setShowAddModal(true);
		} else {
			setShowUploadModal(true);
		}
	}

	const uploadModal = showUploadModal ? (
		<UploadEligibilityFile
			partner={partner}
			isFirstUpload={!users?.length && searchValue === ''}
			upload={upload}
			uploadError={uploadError}
			loading={uploadLoading}
			isModal={true}
			onClickCancel={(): void => setShowUploadModal(false)}
			onUploadSuccess={onUploadSuccess}
		/>
	) : null;

	const shouldShowExceededModal = uploadData?.isSignificantOverage && eligibilityFileExceededModalEnabled;
	const onCloseUploadSuccessModal = (): void => {
		setShowUploadSuccessModal(false);
		if (!shouldShowExceededModal) {
			setShowPathwaysSegmentChangeModal(shouldShowPathwaysSegmentChangeModal);
		}
		setShowExceededModal(Boolean(shouldShowExceededModal));
	};

	const uploadSuccessModal = (
		<UploadSuccessModal
			isOpen={showUploadSuccessModal}
			onClose={() => onCloseUploadSuccessModal()}
			canShowStats={canShowStats}
			added={added}
			removed={removed}
			duplicates={duplicates}
			addedSegmentCount={addedSegmentCount}
			deletedSegmentCount={deletedSegmentCount}
		/>
	);

	const onCloseExceededModal = (): void => {
		setShowExceededModal(false);
		setShowPathwaysSegmentChangeModal(shouldShowPathwaysSegmentChangeModal);
	};

	const eligibilityExceededModal = (
		<EligibilityExceededModal
			contractCoveredLives={partner.contract_covered_lives}
			eligibilityFileCoveredLives={uploadData?.totalCount ?? 0}
			partnerName={partner.name}
			onClose={() => onCloseExceededModal()}
			isOpen={showExceededModal}
		/>
	);

	const pathwaysSegmentChangeModal = (
		<PathwaysSegmentChangeModal
			partnerId={partner.id}
			onClose={() => setShowPathwaysSegmentChangeModal(false)}
			isOpen={showPathwaysSegmentChangeModal}
		/>
	);

	const deleteModal = currentUserForDeletion ? (
		<DeleteModal
			partnerId={partner.id}
			partnerUserId={currentUserForDeletion.partnerUserId}
			userId={currentUserForDeletion.id}
			onDeleteSuccess={onDeleteSuccess}
			onClickCancel={(): void => setCurrentUserForDeletion(null)}
		/>
	) : null;

	const addModal = showAddModal ? (
		<AddModal
			partnerId={partner.id}
			onAddSuccess={onAddSuccess}
			onClickCancel={(): void => setShowAddModal(false)}
			openFileUpload={openUploadModal}
		/>
	) : null;

	if (!loading && !users?.length && !error && searchValue === '') {
		return (
			<>
				<UploadFirstEligibilityFile partner={partner} openModal={(): void => openUploadModal(true)} />
				{uploadModal}
			</>
		);
	}

	return (
		<TableContainer>
			<HeaderContainer noPadding>
				<TopHeaderContainer
					partner={partner}
					totalEligibleUsers={totalEligibleUsers}
					totalRedeemed={totalRedeemed}
					emailData={eligibilityData?.emails}
					loadingEligibility={loading}
				/>
				<BottomHeaderContainer>
					<SearchWrapper>
						<FormInput
							noMargin
							label={formatMessage(messages.searchPlaceholder)}
							onChange={onSearchChange}
							name="search"
							value={searchValue}
							Icon={Search}
							noValidation
						/>
					</SearchWrapper>
					<ButtonGroup>
						<SyncButton partnerId={partner.id} />
						<DownloadEligibilityButton partnerId={partner.id} />
						<PrimaryButton data-testid="open-add-modal" onPress={openAddModal}>
							{formatMessage(messages.addButtonText)}
						</PrimaryButton>
					</ButtonGroup>
				</BottomHeaderContainer>
			</HeaderContainer>
			<UsersTableContainer
				partner={partner}
				searchValue={searchValue}
				pageIndex={pageIndex}
				setPageIndex={setPageIndex}
				showUploadModal={showUploadModal}
				setShowUploadModal={setShowUploadModal}
				setCurrentUserForDeletion={setCurrentUserForDeletion}
				onUploadSuccess={onUploadSuccess}
				upload={upload}
				uploadError={uploadError}
				uploadLoading={uploadLoading}
			/>
			{uploadSuccessModal}
			{deleteModal}
			{addModal}
			{eligibilityExceededModal}
			{pathwaysSegmentChangeModal}
		</TableContainer>
	);
}

export function EligibilityContent({ partner, logId }: { partner: Partner; logId?: string }): ReactElement {
	const [pageIndex, setPageIndex] = useState(0);
	const history = useHistory();
	const isChSftpOn = useCalmHealthSftpIntegration();

	const { upload: chUpload, isUploading: chUploadLoading } = useUploadSftpEligibilityFile(partner.id);
	const [c4bUpload, { loading: c4bUploadLoading, error: c4bUploadError }] = useUploadEligibilityFile(
		partner.id,
		false,
	);
	const PAGE_SIZE = 10;
	const {
		data: latestUploads,
		loading: latestUploadsLoading,
		clearCache,
	} = useEligibilityUploads({
		partnerId: partner.id,
		pageIndex: pageIndex,
		pageSize: PAGE_SIZE,
	});
	const isFirstUpload = latestUploads?.eligibility_uploads?.length === 0;
	const isCalmHealth = isCalmHealthProductSKU(partner.product_sku);
	const upload = isCalmHealth ? chUpload : c4bUpload;
	const loading = isCalmHealth ? chUploadLoading : c4bUploadLoading;

	return (
		<>
			{logId ? (
				<>
					<StyledNav>
						<StyledBackLink
							backgroundColor="transparent"
							textColor="buttonSecondaryText"
							hideShadow
							Icon={BackArrow}
							href="/users#eligibility"
							onPress={() => {
								history.replace({ search: '', hash: '#eligibility' });
							}}
						>
							Back to Logs
						</StyledBackLink>
					</StyledNav>
					<LogDetails partner={partner} id={logId} isCalmHealth={isCalmHealth} />
				</>
			) : (
				<>
					{isChSftpOn && (
						<UploadEligibilityFile
							partner={partner}
							isFirstUpload={isFirstUpload}
							upload={upload}
							clearCache={clearCache}
							loading={loading}
							uploadError={c4bUploadError}
							isModal={false}
						/>
					)}
					<LogsTable
						eligibilityUploadData={latestUploads}
						loading={latestUploadsLoading}
						pageIndex={pageIndex}
						setPageIndex={setPageIndex}
						pageSize={PAGE_SIZE}
					/>
				</>
			)}
		</>
	);
}

export default function Users(): ReturnType<FC> {
	const partner = useDefinedPartner();
	const { data: integrationType } = useIntegrationType(partner?.id);
	const { user } = useUser();
	const { data: flagValuesForEligibility } = useFeatureFlags('b2b-block-eligibility-endpoint');
	const blockEligibilityEndpoint =
		flagValuesForEligibility && flagValuesForEligibility['b2b-block-eligibility-endpoint'];
	const isChSftpOn = useCalmHealthSftpIntegration();
	const { hash, search } = useLocation();
	const query = querystring.parse(search);
	const logId = query?.logid as string;
	const isCalmHealth = isCalmHealthProductSKU(partner?.product_sku);
	const activePage = hash === '' ? (!isCalmHealth ? '#users' : '#eligibility') : hash;
	const defaultID = isCalmHealth ? 'eligibility' : 'users';
	const isEligibilitySection = activePage === '#eligibility' && isChSftpOn;

	const { formatMessage } = useIntl();

	if (!partner || !integrationType) {
		return null;
	}

	if (
		user.accessPolicy?.allowed_user_role === 'partner_portal_role_account_manager_non_us' &&
		partner?.slug === 'comcast'
	) {
		return <div>{formatMessage(messages.permissionError)}</div>;
	}

	if (integrationType === IntegrationType.ACCESS_CODES) {
		return <Redirect to={`/${partner.id}/access-codes`} />;
	}
	if (
		!partner.supports_eligibility_list &&
		(integrationType !== IntegrationType.ELIGIBILITY_FILE || !isChSftpOn)
	) {
		return <Redirect to={`/${partner.id}/home`} />;
	}

	if (blockEligibilityEndpoint) {
		return <div>{formatMessage(messages.eligibilityBlockedErrorMessage)}</div>;
	}

	return (
		<>
			<InitialPageAnalytics />
			{isChSftpOn && (
				<StyledNav aria-label="Users page navigation">
					{!isCalmHealth && (
						<AccountNavLink
							to={`users#users`}
							defaultId={defaultID}
							id="users"
							data-testid="users-users-tab-link"
						>
							Users
						</AccountNavLink>
					)}
					<AccountNavLink
						to={`users#eligibility`}
						defaultId={defaultID}
						id="eligibility"
						data-testid="users-eligibility-tab-link"
						isActive={isEligibilitySection}
					>
						Eligibility
					</AccountNavLink>
				</StyledNav>
			)}
			{(activePage === '#users' || !isChSftpOn) && <UsersContent partner={partner} />}
			{isEligibilitySection && <EligibilityContent partner={partner} logId={logId} />}
		</>
	);
}
